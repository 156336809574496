import { ApiSuccessResponse } from "../../_network/response";
import request from "../../_network/request";
import { EmployeeProfile } from "./params";

const GetCurrent = (): Promise<ApiSuccessResponse<EmployeeProfile>> => {
  return request({
    url: `employee/current`,
    method: "GET",
  });
};

export const EmployeeApi = {
  GetCurrent,
};

