import { IonBackButton, IonButton, IonButtons, IonCol, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonPage, IonRow, IonSpinner, IonTitle, IonToolbar, useIonToast } from '@ionic/react';
import { useState } from 'react';
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { eyeOutline, eyeOffOutline } from 'ionicons/icons';
import { AuthApi } from '../../_services/auth-api';
import { ApiErrorResponse } from '../../_network/response';

const ChangePasswordPage: React.FC = () => {
  const [toast] = useIonToast();

  interface form_param {
    currentPassword: string;
    newPassword: string;
    newPasswordRepeat: string;
  }

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showNewPasswordRepeat, setShowNewPasswordRepeat] = useState(false);
  const [updating, setUpdating] = useState(false)

  const ValidationSchema = yup.object().shape({
    currentPassword:  yup.string().required(),
    newPassword: yup.string().required().min(4),
    newPasswordRepeat: yup.string().required().oneOf([yup.ref("newPassword")], "Your new password and confirmation is different"),
  })
  const { register, handleSubmit, setError, formState: { errors }, reset } = useForm<form_param>({ mode: 'onChange', resolver: yupResolver(ValidationSchema) });

  const onSubmitHandler = async (data: form_param) => {
    try {
      console.log(data)
      setUpdating(true)
      await AuthApi.ChangePassword(data.currentPassword, data.newPassword)
      toast({ message: "Change Password Success", duration: 1500, position: "top", color: "success" })
      reset()
    } catch (error) {
      if (error as ApiErrorResponse) {
        (error as ApiErrorResponse).other_errors.forEach((e) => {
          switch (e.field) {
            case "oldPassword":
              setError("currentPassword", { type: "focus", message: e.message }, { shouldFocus: true });
              break;
            case "newPassword":
              setError("newPassword", { type: "focus", message: e.message }, { shouldFocus: true });
              break;
          }
        })
        toast({ message: (error as ApiErrorResponse).message, duration: 1500, position: "top", color: "danger", cssClass: "max-w-[480px] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" })
      } else {
        console.log("Unknown error:", error);
        toast({ message: "Internal Error", duration: 1500, position: "top", color: "danger" })
      }
    } finally {
      setUpdating(false)
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/account" />
          </IonButtons>
          <IonTitle>Change Password</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
      <form key="change-password-form" onSubmit={handleSubmit(onSubmitHandler)}>
        <IonRow>
          <IonCol>
            <IonItem lines="full" style={{ "--padding-start": "0px" }}>
              <IonInput {...register("currentPassword")} type={showCurrentPassword ? "text" : "password"} name="currentPassword" placeholder="Your current password" ></IonInput>
              <IonIcon icon={showCurrentPassword ? eyeOutline : eyeOffOutline} onClick={() => {
                setShowCurrentPassword(!showCurrentPassword)
              }}></IonIcon>
            </IonItem>
            <p className="text-left mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.currentPassword && <>{errors.currentPassword.message}</>)}</p>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonItem lines="full" style={{ "--padding-start": "0px" }}>
              <IonInput {...register("newPassword")} type={showNewPassword ? "text" : "password"} name="newPassword" placeholder="Your new password" ></IonInput>
              <IonIcon icon={showNewPassword ? eyeOutline : eyeOffOutline} onClick={() => {
                setShowNewPassword(!showNewPassword)
              }}></IonIcon>
            </IonItem>
            <p className="text-left mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.newPassword && <>{errors.newPassword.message}</>)}</p>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonItem lines="full" style={{ "--padding-start": "0px" }}>
              <IonInput {...register("newPasswordRepeat")} type={showNewPasswordRepeat ? "text" : "password"} name="newPasswordRepeat" placeholder="Cofirm new password" ></IonInput>
              <IonIcon icon={showNewPasswordRepeat ? eyeOutline : eyeOffOutline} onClick={() => {
                setShowNewPasswordRepeat(!showNewPasswordRepeat)
              }}></IonIcon>
            </IonItem>
            <p className="text-left mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.newPasswordRepeat && <>{errors.newPasswordRepeat.message}</>)}</p>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonButton type="submit" expand="block" size="default" disabled={updating} >
              Update Password
              {updating && <IonSpinner name="crescent" className="ml-2" />}
            </IonButton>
          </IonCol>
        </IonRow>
      </form>
      </IonContent>
    </IonPage>
  );
};

export default ChangePasswordPage;
