import { ApiSuccessResponse } from "../../_network/response";
import request from "../../_network/request";
import { TransactionConfig, TransactionDraft, Transactions } from "./params";

const GetConfig = (): Promise<ApiSuccessResponse<TransactionConfig>> => {
  return request({
    url: `transaction/config`,
    method: "GET",
  });
};

const CheckTransactionRequest = (amount: number): Promise<ApiSuccessResponse<TransactionDraft>> => {
  return request({
    url: `transaction/request/check`,
    method: "POST",
    data: {
      amount: amount
    }
  });
};

const CreateTransactionRequest = (amount: number, description: string): Promise<ApiSuccessResponse<void>> => {
  return request({
    url: `transaction/request/create`,
    method: "POST",
    data: {
      amount: amount,
      description: description
    }
  });
};

const GetHistory = (year: number, month: number): Promise<ApiSuccessResponse<Transactions>> => {
  return request({
    url: `transaction/history?year=${year}&month=${month}`,
    method: "GET",
  });
};

export const TransactionApi = {
  GetConfig,
  CheckTransactionRequest,
  CreateTransactionRequest,
  GetHistory,
};

