import { IonButton, IonButtons, IonChip, IonContent, IonHeader, IonIcon, IonModal, IonTitle, IonToolbar } from "@ionic/react"
import { closeOutline } from "ionicons/icons"
import TncEnglish from "./tnc_english"

export const TnCModal = (props: {show: boolean, setShow: React.Dispatch<React.SetStateAction<boolean>>}) => {
    return <>
      <IonModal isOpen={props.show} >
        <IonHeader>
          <IonToolbar>
            <IonTitle>Terms & Condition</IonTitle>
            <IonButtons slot="end">
              <IonButton className='text-danger' onClick={() => { props.setShow(false) }}>
                <IonIcon slot="icon-only" icon={closeOutline} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <div className='text-right'>
          </div>
          <TncEnglish></TncEnglish>
          <IonButton className='mt-4' type="button" expand="block" size="default" color={"danger"} onClick={() => props.setShow(false)} >
            Close
          </IonButton>
        </IonContent>
      </IonModal>
    </>
  }