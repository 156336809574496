import { IonButton, IonRow,  IonCol, IonIcon, IonInput, IonItem, IonSelect, IonSelectOption, IonSpinner, useIonToast } from "@ionic/react";
import { useState } from "react";
import { eyeOffOutline } from "ionicons/icons";
import { ApiErrorResponse } from "../../_network/response";
import { useAppDispatch } from "../../redux/hook";
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { eyeOutline } from 'ionicons/icons';
import { loginByAuthTokenRedux } from "../../redux/auth/auth.action";
import { MultiLoginModalState } from './multilogin.modal';
import { AuthApi } from "../../_services/auth-api";
import { TnCModal } from "../../components/tnc/tnc.modal";

const LoginByPhoneNumberForm = (props: {
  setMultiLoginModal: React.Dispatch<React.SetStateAction<MultiLoginModalState>>
}) => {
  interface form_param {
    phoneExt: string;
    phoneNumber: string;
    password: string;
  }
  const [toast] = useIonToast();
  const [showPassword, setShowPassword] = useState(false);
  const [showTnc, setShowTnc] = useState(false);

  const [updating, setUpdating] = useState(false)
  const ValidationSchema = yup.object().shape({
    phoneExt:  yup.string().required(),
    phoneNumber: yup.string().required("phone number is required"),
    password: yup.string().required("password is required"),
  })
  const { register, handleSubmit, setError, formState: { errors }, trigger } = useForm<form_param>({ mode: 'onChange', resolver: yupResolver(ValidationSchema) });
  const dispatch = useAppDispatch();

  const onSubmitHandler = async (data: form_param) => {
    try {
      setUpdating(true)
      const resp = await AuthApi.LoginEmployeeByPhoneNumber(data.phoneExt + data.phoneNumber, data.password)

      if (resp.data.loginToken.length === 1) {
        dispatch(loginByAuthTokenRedux(resp.data.loginToken[0].authToken))
        toast({ message: "Login success", duration: 1500, position: "top", color: "success" })
      } else {
        props.setMultiLoginModal({ isOpen: "open", initialData: resp.data.loginToken })
      }
    } catch (error) {
      if (error as ApiErrorResponse) {
        (error as ApiErrorResponse).other_errors.forEach((e) => {
          switch (e.field) {
            case "phoneNumber":
              setError("phoneNumber", { type: "focus", message: e.message }, { shouldFocus: true });
              break;
            case "password":
              setError("password", { type: "focus", message: e.message }, { shouldFocus: true });
              break;
          }
        })
        toast({ message: (error as ApiErrorResponse).message, duration: 1500, position: "top", color: "danger", cssClass: "max-w-[480px] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" })
      } else {
        console.log("Unknown error:", error);
        toast({ message: "Internal Error", duration: 1500, position: "top", color: "danger" })
      }
    } finally {
      setUpdating(false)
    }
  };

  return <>
    <TnCModal show={showTnc} setShow={setShowTnc}></TnCModal>
    <form key="login-by-phonenumber-form" onSubmit={handleSubmit(onSubmitHandler)}>
      <IonRow>
        <IonCol>
          {/* <div className="block text-left text-sm"><IonLabel>No. Telepon</IonLabel></div> */}
          <IonItem lines="full" style={{ "--padding-start": "0px" }}>
            {/* <IonLabel>  */}
              <IonSelect {...register("phoneExt")} className='text-gray-400' placeholder="" defaultValue={"+65"} value="+65">
                <IonSelectOption value="+60">+60</IonSelectOption>
                <IonSelectOption value="+65">+65</IonSelectOption>
              </IonSelect>
              {/* <span className='text-gray-400'>+65</span>  */}
            {/* </IonLabel> */}
            <div className="border-r ml-1 mr-1 h-1/2"></div>
            <IonInput {...register("phoneNumber")} className=" text-black" type="tel" name="phoneNumber" placeholder="Your Phone Number" ></IonInput>
          </IonItem>
          <p className="text-left mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.phoneNumber && <>{errors.phoneNumber.message}</>)}</p>
          <p className="text-left mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.phoneExt && <>{errors.phoneExt.message}</>)}</p>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          {/* <div className="text-left text-sm"><IonLabel>Password</IonLabel></div> */}
          <IonItem lines="full" style={{ "--padding-start": "0px" }}>
            <IonInput {...register("password")} type={showPassword ? "text" : "password"} name="password" placeholder="Your Password" ></IonInput>
            <IonIcon icon={showPassword ? eyeOutline : eyeOffOutline} onClick={() => {
              setShowPassword(!showPassword)
            }}></IonIcon>
          </IonItem>
          <p className="text-left mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.password && <>{errors.password.message}</>)}</p>
        </IonCol>
      </IonRow>
      <div className='text-gray-500 text-center mb-4'>
        <p className='mt-2 text-sm'>

          <span className="align-top ml-2" >By Signing In, I Agree with the <span onClick={() => setShowTnc(true)} className="text-primary cursor-pointer">terms and conditions</span></span>
        </p>
      </div>
      <IonRow>
        <IonCol>
          <IonButton type="submit" expand="block" size="default" disabled={updating} >
            Login
            {updating && <IonSpinner name="crescent" className="ml-2" />}
          </IonButton>
        </IonCol>
      </IonRow>
    </form>

  </>
}

export default LoginByPhoneNumberForm;
