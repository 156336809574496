import { Redirect, Route } from 'react-router-dom';
import { IonRouterOutlet } from "@ionic/react";
import LoginPage from "../pages/login";
import CompanyRegisterPage from '../pages/company-register';

const useNonLoginRoute = () => {
  const route = <IonRouterOutlet>
    <Route path="/" component={LoginPage} exact={true} />
    <Route path="/company/register" component={CompanyRegisterPage} exact={true} />
    <Route render={() => <Redirect to={"/"} />} />
  </IonRouterOutlet>

  return route;
};

export default useNonLoginRoute;
