import { IonAvatar, IonButton, IonContent, IonInput, IonItem, IonLabel, IonPage, IonRefresher, IonRefresherContent, RefresherEventDetail, useIonToast } from "@ionic/react";
import { useEffect, useState } from "react";
import useCurrentCompany from "../../hook/current_company";
import useCurrentEmployee from "../../hook/current_employee";
import useCurrentEwaConfig from "../../hook/current_ewa_config";
import { NewCurrencyCode } from "../../_helper/currencyCode";
import WithdrawModal, { WithdrawModalState } from "./withdraw.modal";
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import moment from 'moment'
import HistorySection from "./history.section";
import Avatar from '../../assets/images/avatar.svg';
import { Transaction } from "../../_services/transaction-api/params";
import { ApiErrorResponse, UnknownError } from "../../_network/response";
import { TransactionApi } from "../../_services/transaction-api";
import WithdrawSuccessModal, { WithdrawSuccessModalState } from "./withdraw-success.modal";

const HomePage = () => {
  const [withdrawModal, setWithdrawModal] = useState<WithdrawModalState>({ isOpen: "close", amount:0 });
  const [withdrawSuccessModal, setWithdrawSuccessModal] = useState<WithdrawSuccessModalState>({ isOpen: "close", amount:0 });

  const [current_employee] = useCurrentEmployee()
  const [current_company] = useCurrentCompany()
  const [current_ewa_config, _, mutate_ewa_config] = useCurrentEwaConfig()
  
  const now = moment(Date.now())
  const [year, setYear] = useState(now.year())
  const [month, setMonth] = useState(now.month() + 1)
  const [histories, history_err, mutate_history] = useHistory(year, month)

  useEffect(() => {
    if(current_ewa_config !== undefined){
      setValue('withdrawAmount', current_ewa_config.minimumRequestAmount)
    }
  }, [current_ewa_config])

  const min_withdraw_amount = current_ewa_config ? current_ewa_config.minimumRequestAmount > 0 ? current_ewa_config.minimumRequestAmount : 1 : 1
  const max_withdraw_amount = current_ewa_config ? current_ewa_config.maximumRequestAmount > 0 ? current_ewa_config.maximumRequestAmount : 999999999 : 999999999
  const ValidationSchema = yup.object().shape({
    withdrawAmount:  yup.number()
      .min(min_withdraw_amount, "Withdraw amount must be greater than or equal to " + min_withdraw_amount.toString())
      .max(max_withdraw_amount, "Withdraw amount must be less than or equal to " + max_withdraw_amount.toString()),
  })
  const { register, handleSubmit, formState: { errors }, setValue, trigger} = useForm<{withdrawAmount: number}>({ mode: 'onChange', resolver: yupResolver(ValidationSchema) });

  const onSubmitHandler = async (data: {withdrawAmount: number}) => {
    setWithdrawModal({amount: data.withdrawAmount, isOpen:"open"})
  }

  const refreshState = async (event: CustomEvent<RefresherEventDetail>) => {
    await mutate_history(year, month)
    await mutate_ewa_config()
    event.detail.complete();
  }

  return <IonPage>
    <IonContent>
      <IonRefresher slot="fixed" onIonRefresh={refreshState}>
        <IonRefresherContent></IonRefresherContent>
      </IonRefresher>
      <WithdrawModal state={withdrawModal} setState={setWithdrawModal} onSubmitDone={(amount)=>{
        mutate_history(year, month)
        mutate_ewa_config()
        setWithdrawSuccessModal({...withdrawSuccessModal, isOpen:"open", amount: amount})
      }} current_company={current_company} current_employee={current_employee}></WithdrawModal>
      <WithdrawSuccessModal state={withdrawSuccessModal} setState={setWithdrawSuccessModal} current_company={current_company}></WithdrawSuccessModal>
      
      <form key="withdraw-form" onSubmit={handleSubmit(onSubmitHandler)}>
      <div className='p-4 bg-primary rounded-b-3xl'>
        <div className="flex bg-primary rounded-lg p-1.5">
          <IonAvatar>
            <img alt={current_employee?.name} src={Avatar} />
          </IonAvatar>
          <div className="flex flex-col px-5">
            <h4 className="font-bold text-white text-lg m-0">{current_employee?.name}</h4>
            <p className="flex-1 text-sm text-slate-100">{current_company?.formalName}</p>
          </div>
        </div>
        <hr className="border-white border h-0 mb-2"/>
        <div className="flex flex-row justify-between">
          <div className="text-white">
            <span className="text-sm align-top">Your Balance</span>
            <div className="text-xl font-bold">
              { NewCurrencyCode(current_company?.countryCode) + ' ' + current_ewa_config?.currentAllowance.toLocaleString("en-SG", { maximumFractionDigits: 2 })}
            </div>
          </div>
          <span className="bg-gray-100 text-gray-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300 h-fit">
          { moment(current_ewa_config?.currentPeriodStartDate).format('DD MMM YY') } - { moment(current_ewa_config?.currentPeriodEndDate).format('DD MMM YY') }
          </span>
        </div>
        
        <IonItem className="border border-b-0 rounded mt-4">
          <IonLabel>{ NewCurrencyCode(current_company?.countryCode) }</IonLabel>
          <IonInput type="number" placeholder="Withdraw Amount" step="any"
          {...register("withdrawAmount")}
          onWheel={(e) => e.currentTarget.blur()}  
          onIonBlur={
            (e) => {
              var amount = Number(e.target.value)
              var max = current_ewa_config ? current_ewa_config.maximumRequestAmount > 0 ? current_ewa_config.maximumRequestAmount : 999999999 : 999999999
              if(current_ewa_config && current_ewa_config.currentAllowance < max){
                max = current_ewa_config.currentAllowance
              }
              if(amount > max) {
                amount = max
              } 
              if(amount < 0) {
                amount = 0
              } 
  
              if(isNaN(amount)){
                amount = 0
              }
              setValue('withdrawAmount', Math.floor(amount * 100) / 100)
              trigger('withdrawAmount')
            }
          }
          ></IonInput>
        </IonItem>
        <p className="text-left mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.withdrawAmount && <>{errors.withdrawAmount.message}</>)}</p>

        <p className='text-xs text-white'> Amount Range: 
          <span className="font-bold">
            <span> { NewCurrencyCode(current_company?.countryCode) } </span>
            <span> { current_ewa_config ? current_ewa_config.minimumRequestAmount > 0 ? current_ewa_config.minimumRequestAmount : "1" : "1" }</span>
            <span>-</span> 
            <span>{ current_ewa_config ? current_ewa_config.maximumRequestAmount > 0 ? current_ewa_config.maximumRequestAmount : "~" : "~" } </span> 
          </span> 
        </p>
        {
          current_ewa_config ? current_ewa_config.maxTransactionPerPeriod > 0 ? 
          <p className='text-xs text-white'> Transaction Limit: <span className="font-bold">{ current_ewa_config.countTransactionCurrentPeriod }/{ current_ewa_config.maxTransactionPerPeriod }</span> </p> :
          null : null
        }
        
        <IonButton type="submit" color="light" expand="block" className="mt-4" disabled={!current_ewa_config?.ewaEnabled}>
          Withdraw
        </IonButton>
        {
          !current_ewa_config?.ewaEnabled &&
          <p className='self-center text-sm text-warning'> { current_ewa_config?.ewaDisabledReason } </p>
        }
      </div>
      </form>
      
      <HistorySection 
        transactions={histories}
        year={year}
        month={month}
        setYear={setYear}
        setMonth={setMonth}
        current_company={current_company}
      ></HistorySection>
    </IonContent>
  </IonPage>
}

export default HomePage;

function useHistory(year: number, month: number) :[Transaction[], ApiErrorResponse | undefined, (_year: number, _month: number)=>{}] {
  const [toast] = useIonToast();
  const [result, setResult] = useState<Transaction[]>([]);
  const [error, setError] = useState<ApiErrorResponse>();

  async function fetch(_year :number, _month :number) {
    try {
      var res = await TransactionApi.GetHistory(_year, _month);
      setResult(res.data.transactions);
    } catch (error) {
      if(error as ApiErrorResponse){
        toast({ message: (error as ApiErrorResponse).message, duration: 1500, position: "top", color: "danger", cssClass: "max-w-[480px] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" })
        setError((error as ApiErrorResponse));
      } else {
        console.log("Unknown error:", error);
        toast({ message: "Internal Error", duration: 1500, position: "top", color: "danger" })
        setError(UnknownError(""));
      } 
    } finally {}
  }
  useEffect(() => {
    fetch(year, month)
  }, [month]);


  return [result, error, fetch];
}