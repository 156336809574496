import { ApiSuccessResponse } from "../../_network/response";
import request from "../../_network/request";
import { LoginTokens } from "./params";

const LoginEmployeeByPhoneNumber = (phoneNumber: string, password: string): Promise<ApiSuccessResponse<LoginTokens>> => {
  return request({
    url: `auth/login/employee/phone`,
    method: "POST",
    data: {
      phoneNumber: phoneNumber,
      password: password,
    },
  });
};

const ChangePassword = (oldPassword: string, newPassword: string): Promise<ApiSuccessResponse<void>> => {
  return request({
    url: `auth/password/change`,
    method: "POST",
    data: {
      oldPassword: oldPassword,
      newPassword: newPassword,
    },
  });
};

export const AuthApi = {
    LoginEmployeeByPhoneNumber,
    ChangePassword,
};
