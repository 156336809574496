import Logo from '../../assets/images/logo-login-full.png';
import { IonCol, IonContent, IonGrid, IonPage, IonRow } from "@ionic/react";
import { useState } from "react";
import MultiLoginModal, { MultiLoginModalState } from './multilogin.modal';
import LoginByPhoneNumberForm from './login-phonenumber.form';

const LoginPage = () => {
  const [multiLoginModal, setMultiLoginModal] = useState<MultiLoginModalState>({ isOpen: "close", initialData: [] });

  return <>
    <IonPage>
      <IonContent fullscreen className="ion-padding ion-text-center">
        <IonGrid>
          <IonRow>
            <IonCol>
              <div className='flex justify-center items-center'>
                <img src={Logo} alt="gajiku" className="mb-10 h-20" />
              </div>
            </IonCol>
          </IonRow>

          <MultiLoginModal state={multiLoginModal} setState={setMultiLoginModal}></MultiLoginModal>
          <LoginByPhoneNumberForm setMultiLoginModal={setMultiLoginModal}></LoginByPhoneNumberForm> 
         
          <div className='text-gray-500 text-center mt-8 mb-4'>
            <p className='mt-2'>
              Have a problem? Need Help? Want to get employee access? <a href={"https://api.whatsapp.com/send?phone=6281231747236&text=Hi+Gajiku"} target="_blank" rel="noreferrer" className="text-primary">Contact Us</a>
            </p>
          </div>
          <div className='text-gray-500 text-center mt-4 mb-4'>
            <p className='mt-2'>
              For Employer. <a href={"/company/register"} rel="noreferrer" className="text-primary">Register Your Company</a>
            </p>
          </div>
        </IonGrid>
      </IonContent>
    </IonPage>
  </>
}

export default LoginPage;
