import { createAnimation, IonButton, IonModal } from "@ionic/react";
import { NewCurrencyCode } from "../../_helper/currencyCode";
import { Company } from "../../_services/company-api/params";
import SuccessIcon from "../../assets/images/success-icon.png";

export type WithdrawSuccessModalState = {
  isOpen: "open" | "close"
  amount: number
}

const WithdrawSuccessModal = (props: {state: WithdrawSuccessModalState, setState: React.Dispatch<React.SetStateAction<WithdrawSuccessModalState>>, current_company?: Company}) => {

  const enterAnimation = (baseEl: HTMLElement) => {
    const root = baseEl.shadowRoot;

    const backdropAnimation = createAnimation()
      .addElement(root?.querySelector('ion-backdrop')!)
      .fromTo('opacity', '0.01', 'var(--backdrop-opacity)');

    const wrapperAnimation = createAnimation()
      .addElement(root?.querySelector('.modal-wrapper')!)
      .keyframes([
        { offset: 0, opacity: '0', transform: 'scale(0)' },
        { offset: 1, opacity: '0.99', transform: 'scale(1)' },
      ]);

    return createAnimation()
      .addElement(baseEl)
      .easing('ease-out')
      .duration(250)
      .addAnimation([backdropAnimation, wrapperAnimation]);
  };

  const leaveAnimation = (baseEl: HTMLElement) => {
    return enterAnimation(baseEl).direction('reverse');
  };

  return <IonModal 
    id="message-modal" 
    enterAnimation={enterAnimation}
    leaveAnimation={leaveAnimation}
    isOpen={props.state.isOpen === 'open'} 
    onDidDismiss={(e)=>{
      if(props.state.isOpen === 'open') {
        props.setState( { ...props.state, amount: 0, isOpen: "close"} )
      }
    }}
  >
    <div className="mb-4 mt-4 mr-4 ml-4">
      <h1 className="text-center">Withdraw Success</h1>
      <section className="mx-auto mb-4 mt-4 flex">
        <img className="mx-auto" src={SuccessIcon} alt="screenshot" />
      </section> 
      <div className="text-center">
        <div className="mb-4">Your request <span className="text-lg font-bold">{ NewCurrencyCode(props.current_company?.countryCode) + ' ' + props.state.amount.toLocaleString("en-SG", { maximumFractionDigits: 2 })}</span> is on process within 1x24 hour</div>
        <div>Please regularyly check your balance account</div>
      </div>
      <div className="text-center">
        <IonButton className="mt-10" type="button" size="default" onClick={ () => props.setState( { ...props.state, amount: 0, isOpen: "close"} )} >
          Back To Home
        </IonButton>
      </div>
    </div>
</IonModal>
}

export default WithdrawSuccessModal;

