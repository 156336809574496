import AuthActionTypes from "./auth.types";
import { batch } from "react-redux";
import GlobalActionTypes from "../global/global.types";
// import { toast } from 'react-toastify';
// import { AuthApiServices } from "../../_services/auth-api.services";
import { AuthApi } from "../../_services/auth-api";
// import { Intent } from "@blueprintjs/core";

export const loginByAuthTokenRedux = (authToken: string) => {
  return async (dispatch: any) => {
    try {
      batch(() => {
        dispatch({
          type: AuthActionTypes.LOGIN_SUCCESS,
          payload: authToken,
        });
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        // toast.success("Login Sukses")
      });
    } catch (error: any) {
      batch(() => {
        dispatch({ type: AuthActionTypes.LOGIN_FAILURE, payload: error });
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        // toast.error("Login Gagal. " + error.message)
      });
    }
  };
}

export const loginRedux = (username: string, password: string) => {
  return async (dispatch: any) => {
    try {
      batch(() => {
        dispatch({ type: AuthActionTypes.LOGIN });
        dispatch({ type: GlobalActionTypes.SHOW_LOADING });
      });
      const login = await AuthApi.LoginEmployeeByPhoneNumber(username, password)
      // const login = await AuthApiServices.LoginApi_GlobalLoginEmployeeByPhoneNumber(username, password);
      batch(() => {
        dispatch({
          type: AuthActionTypes.LOGIN_SUCCESS,
          payload: login.data.loginToken[0].authToken,
        });
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        // toast.success("Login Sukses")
        // dispatch({
        //   type: GlobalActionTypes.SHOW_ALERT,
        //   payload: {
        //     showAlert: true,
        //     alertMessage: "Login Success",
        //     alertType: "SUCCESS",
        //   },
        // });
      });
    } catch (error: any) {
      batch(() => {
        dispatch({ type: AuthActionTypes.LOGIN_FAILURE, payload: error });
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        // toast.error("Login Gagal. " + error.message)
        // dispatch({
        //   type: GlobalActionTypes.SHOW_ALERT,
        //   payload: {
        //     showAlert: true,
        //     alertMessage: error,
        //     alertType: "DANGER",
        //   },
        // });
      });
    }
  };
};

export const logoutRedux = () => {
  return async (dispatch: any) => {
    dispatch({ type: AuthActionTypes.LOGOUT });
  };
};
