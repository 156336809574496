import { useIonToast } from '@ionic/react';
import { useEffect, useState } from 'react'
import { ApiErrorResponse } from '../_network/response';
import { UnknownError } from '../_network/response';
import { EmployeeProfile } from '../_services/employee-api/params';
import { EmployeeApi } from '../_services/employee-api';

function useCurrentEmployee() :[EmployeeProfile | undefined, ApiErrorResponse | undefined, ()=>{}] {
    const [toast] = useIonToast();
    const [result, setResult] = useState<EmployeeProfile>();
    const [error, setError] = useState<ApiErrorResponse>();
    
    async function mutate() {
      fetch()
    }
  
    async function fetch() {
      try {
        var res = await EmployeeApi.GetCurrent();
        setResult(res.data);
      } catch (error) {
        if(error as ApiErrorResponse){
            toast({ message: (error as ApiErrorResponse).message, duration: 1500, position: "top", color: "danger", cssClass: "max-w-[480px] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" })
            setError((error as ApiErrorResponse));
        } else {
            console.log("Unknown error:", error);
            toast({ message: "Internal Error", duration: 1500, position: "top", color: "danger" })
            setError(UnknownError(""));
        }
      } finally {
      }
    }

    useEffect(() => {
      fetch()
    }, [])
  
    return [result, error, mutate];
}

export default useCurrentEmployee