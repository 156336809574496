import { ApiSuccessResponse } from "../../_network/response";
import request from "../../_network/request";
import { Company } from "./params";

const GetCurrent = (): Promise<ApiSuccessResponse<Company>> => {
  return request({
    url: `company/current`,
    method: "GET",
  });
};

export const CompanyApi = {
  GetCurrent,
};

