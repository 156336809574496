const GlobalActionTypes = {
  SET_HEADER: "SET_HEADER",
  SHOW_LOADING: "SHOW_LOADING", 
  HIDE_LOADING: "HIDE_LOADING",
  SET_IMAGE: "SET_IMAGE",
  UNSET_IMAGE: "UNSET_IMAGE",
  SET_LOCATION: "SET_LOCATION",
  UNSET_LOCATION: "UNSET_LOCATION",
  SHOW_SUCCESS: "SHOW_SUCCESS",
  HIDE_SUCCESS: "HIDE_SUCCESS",
  SHOW_ALERT: "SHOW_ALERT", // Loading using toast will not use
  HIDE_ALERT: "HIDE_ALERT", // Loading using toast will not use
  SHOW_LOADING_TABLE: "SHOW_LOADING_TABLE",
  HIDE_LOADING_TABLE: "HIDE_LOADING_TABLE",
  SHOW_LOADING_BUTTON: "SHOW_LOADING_BUTTON",
  HIDE_LOADING_BUTTON: "HIDE_LOADING_BUTTON",
};

export default GlobalActionTypes;
