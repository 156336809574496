import { Redirect, Route } from 'react-router-dom';
import { IonRouterOutlet, IonTabs, IonTabBar, IonTabButton, IonIcon, IonLabel } from "@ionic/react";
import { homeOutline, personOutline } from 'ionicons/icons';
import AccountPage from '../pages/account';
import HomePage from '../pages/home';
import ChangePasswordPage from '../pages/change-password';

const useLoginRoute = () => {
  const route = <>
    <IonTabs>
      <IonRouterOutlet>
        <Route exact path="/main"><HomePage /></Route>
        <Route exact path="/account"><AccountPage /></Route>
        <Route exact path="/account/change-password"><ChangePasswordPage /></Route>
        <Route exact path="/"><Redirect to="/main" /></Route>
        <Route render={() => <Redirect to={"/main"} />} />
      </IonRouterOutlet>
      <IonTabBar slot="bottom" color={"primary"} className='rounded-t-2xl py-1' > 
        <IonTabButton tab="main" href="/main">
          <IonIcon icon={homeOutline} />
          <IonLabel>Home</IonLabel>
        </IonTabButton>
        <IonTabButton tab="account" href="/account">
          <IonIcon icon={personOutline} />
          <IonLabel>Account</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  </>
  return route;
};

export default useLoginRoute;
