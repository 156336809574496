import { createAnimation, IonBackButton, IonButton, IonButtons, IonCol, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonItemDivider, IonItemGroup, IonLabel, IonList, IonModal, IonPage, IonRow, IonSelect, IonSelectOption, IonSpinner, IonTitle, IonToolbar, useIonToast } from '@ionic/react';
import { useState } from 'react';
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import SuccessIcon from "../../assets/images/success-icon.png";
import { useHistory } from 'react-router';

interface form_param {
  companyName: string
  name: string
  email: string
  phoneNumber: string
  position: string
  companySize: string
}
const CompanyRegisterPage: React.FC = () => {
  const [submitting, setSubmitting] = useState(false)
  const [doneModal, setDoneModal] = useState(false)

  const ValidationSchema = yup.object().shape({
    companyName: yup.string().required(),
    name: yup.string().required(),
    email: yup.string().email().required(),
    phoneNumber: yup.string().required(),
    position: yup.string().required(),
    companySize: yup.string().required(),
  })

  const { register, handleSubmit, formState: { errors } } = useForm<form_param>({ mode: 'onChange', resolver: yupResolver(ValidationSchema), defaultValues: {
    companySize: "1-10"
  } });

  const onSubmitHandler = async (data: form_param) => {
    setSubmitting(true)
    setDoneModal(true)
    setSubmitting(false)
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/" />
          </IonButtons>
          <IonTitle>Company Registration</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
      <DoneModal show={doneModal} setShow={setDoneModal}></DoneModal>
      <form key="registration-form" onSubmit={handleSubmit(onSubmitHandler)}>
        <IonList >
          <IonItemGroup>
            <IonItemDivider>
              <IonLabel>Company Information</IonLabel>
            </IonItemDivider>
            <IonItem lines='inset'>
              <IonLabel position='stacked'>Company Name</IonLabel>
              <IonInput {...register("companyName")} name="companyName" placeholder="" ></IonInput>
            </IonItem>
            <p className="text-left ml-4 mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.companyName && <>{errors.companyName.message}</>)}</p>
            <IonItem lines='inset'>
              <IonLabel position='stacked'>Company Size</IonLabel>
              <IonSelect {...register("companySize")}>
                <IonSelectOption value={"1-10"}>1-10 Employees</IonSelectOption>
                <IonSelectOption value={"11-50"}>11-50 Employees</IonSelectOption>
                <IonSelectOption value={"51-100"}>51-100 Employees</IonSelectOption>
                <IonSelectOption value={"100+"}> &gt;100 Employees</IonSelectOption>
              </IonSelect>
            </IonItem>
            <p className="text-left ml-4 mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.companySize && <>{errors.companySize.message}</>)}</p>
          </IonItemGroup>

          <IonItemGroup>
            <IonItemDivider>
              <IonLabel>PIC Information</IonLabel>
            </IonItemDivider>
            <IonItem lines='inset'>
              <IonLabel position='stacked'>Name</IonLabel>
              <IonInput {...register("name")} name="name" placeholder="" ></IonInput>
            </IonItem>
            <p className="text-left ml-4 mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.name && <>{errors.name.message}</>)}</p>
            <IonItem lines="inset">
              <IonLabel position='stacked'>Email</IonLabel>
              <IonInput {...register("email")} name="email" placeholder="" ></IonInput>
            </IonItem>
            <p className="text-left ml-4 mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.email && <>{errors.email.message}</>)}</p>

            <IonItem lines='inset'>
              <IonLabel position='stacked'>Phone Number</IonLabel>
              <IonInput {...register("phoneNumber")} name="phoneNumber" placeholder="" ></IonInput>
            </IonItem>
            <p className="text-left ml-4 mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.phoneNumber && <>{errors.phoneNumber.message}</>)}</p>

            <IonItem lines='inset'>
              <IonLabel position='stacked'>Position</IonLabel>
              <IonInput {...register("position")} name="position" placeholder="" ></IonInput>
            </IonItem>
            <p className="text-left ml-4 mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.position && <>{errors.position.message}</>)}</p>
          </IonItemGroup>
        </IonList>
        
        <IonRow>
          <IonCol>
            <IonButton type="submit" expand="block" size="default" disabled={submitting} >
              Register
              {submitting && <IonSpinner name="crescent" className="ml-2" />}
            </IonButton>
          </IonCol>
        </IonRow>
      </form>
      </IonContent>
    </IonPage>
  );
};

export default CompanyRegisterPage;


const DoneModal = (props: {show: boolean, setShow: React.Dispatch<React.SetStateAction<boolean>>}) => {
  const history = useHistory()
  const enterAnimation = (baseEl: HTMLElement) => {
    const root = baseEl.shadowRoot;

    const backdropAnimation = createAnimation()
      .addElement(root?.querySelector('ion-backdrop')!)
      .fromTo('opacity', '0.01', 'var(--backdrop-opacity)');

    const wrapperAnimation = createAnimation()
      .addElement(root?.querySelector('.modal-wrapper')!)
      .keyframes([
        { offset: 0, opacity: '0', transform: 'scale(0)' },
        { offset: 1, opacity: '0.99', transform: 'scale(1)' },
      ]);

    return createAnimation()
      .addElement(baseEl)
      .easing('ease-out')
      .duration(250)
      .addAnimation([backdropAnimation, wrapperAnimation]);
  };

  const leaveAnimation = (baseEl: HTMLElement) => {
    return enterAnimation(baseEl).direction('reverse');
  };

  return <IonModal 
    id="message-modal" 
    enterAnimation={enterAnimation}
    leaveAnimation={leaveAnimation}
    isOpen={props.show} 
    onDidDismiss={(e)=>{
      if(props.show) {
        props.setShow(false)
        history.push('/')
      }
    }}
  >
    <div className="mb-4 mt-4 mr-4 ml-4">
      <h1 className="text-center">Registration Success</h1>
      <section className="mx-auto mb-4 mt-4 flex">
        <img className="mx-auto" src={SuccessIcon} alt="screenshot" />
      </section> 
      <div className="text-center">
        <div className="mb-4">We will review you request and call you later within 3 working days</div>
      </div>
      <div className="text-center">
        <IonButton className="mt-10" type="button" size="default" onClick={ () => {
          props.setShow(false)
          history.push('/')
        }}>
          OK
        </IonButton>
      </div>
    </div>
</IonModal>
}