import { createAnimation, IonBadge, IonButton, IonButtons, IonChip, IonCol, IonContent, IonHeader, IonIcon, IonItem, IonItemGroup, IonLabel, IonList, IonModal, IonRow, IonTitle, IonToolbar, useIonToast } from "@ionic/react";
import { useState } from "react";
import { Transaction } from "../../_services/transaction-api/params";
import { NewCurrencyCode } from "../../_helper/currencyCode";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import moment from "moment-timezone";
import { closeOutline } from "ionicons/icons";
import { Company } from "../../_services/company-api/params";

const HistorySection = (props: {
  transactions: Transaction[],
  year: number,
  month: number,
  setYear: React.Dispatch<React.SetStateAction<number>>, 
  setMonth: React.Dispatch<React.SetStateAction<number>>, 
  current_company?: Company
}) => {
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Agt", "Sep", "Oct", "Nov", "Dec"];
  const now = moment(Date.now())
  const [historyModal, setHistoryModal] = useState<TransactionHistoryModalState>({isOpen: "close"})

  const nextMonth = () => {
    if(props.month + 1 > 12) {
      props.setMonth(props.month + 1 - 12)
      props.setYear(props.year+1)
    } else {
      props.setMonth(props.month + 1)
    }
  }

  const prevMonth = () => {
    if(props.month - 1 < 1) {
      props.setMonth(props.month - 1 + 12)
      props.setYear(props.year-1)
    } else {
      props.setMonth(props.month - 1)
    }
  }  

  return <>
    <div className="flex flex-row text-center justify-center mt-5 mb-5">
      <button type="button" onClick={prevMonth} 
        className="mr-5 self-center text-white bg-primary-300 hover:bg-primary-800 disabled:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-full text-sm p-1 text-center inline-flex items-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
        disabled={props.year * 100 + props.month <= 202101}
      >
        <FiChevronLeft aria-hidden="true" fill="none" size={"16"}></FiChevronLeft>
      </button>
      <span className="text-lg">{monthNames[props.month-1]} {props.year}</span>
      <button type="button" onClick={nextMonth} 
        className="ml-5 self-center text-white bg-primary-300 hover:bg-primary-800 disabled:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-full text-sm p-1 text-center inline-flex items-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
        disabled={props.year * 100 + props.month >= now.year() * 100 + now.month() + 1}
      >
        <FiChevronRight aria-hidden="true" fill="none" size={"16"}></FiChevronRight>
      </button>
    </div>
    <TransactionHistoryModal state={historyModal} setState={setHistoryModal} current_company={props.current_company}></TransactionHistoryModal>
    <IonList>
      {
        props.transactions.length === 0 && 
        <div className="text-center">No transaction</div>
      }
      {
      props.transactions.map( (e,i) => {
      return <IonItem button key={"history-"+i.toString()} lines="full" onClick={()=>{
        setHistoryModal({...historyModal, isOpen: "open", transaction: e})
      }}>
        <IonLabel>
          <div className="flex flex-row">
            <div className="flex-none">
              <div className="mr-4 text-center">
                <div className="text-md">{ moment.tz(e.createdAt, props.current_company?.timezone ?? 'Etc/UTC' ).format("DD MMM") }</div>
                <div className="text-sm">{ moment.tz(e.createdAt, props.current_company?.timezone ?? 'Etc/UTC' ).format("YYYY") }</div>
                <div className="text-xs">{ moment.tz(e.createdAt, props.current_company?.timezone ?? 'Etc/UTC' ).format("HH:mm") }</div>
              </div>
            </div>
            <div className="flex-auto">
              <div>
                <IonBadge color={"medium"}>Withdraw</IonBadge>
              </div>
              <h2 style={{"fontWeight": "bold"}}>{ NewCurrencyCode(props.current_company?.countryCode) + ' ' + e.amount }</h2>
              <p>{ e.reason }</p>
            </div>
            <div className="self-center">
              {
                e.status === 'SUCCESS' ?
                <IonChip color={"success"}>Success</IonChip> :
                e.status === 'IN_PROCESS' ?
                <IonChip color={"warning"}>In Process</IonChip> :
                e.status === 'FAILED' ?
                <IonChip color={"danger"}>Failed</IonChip>:
                <IonChip color={""}>Unknown</IonChip>
              }
             
            </div>
          </div>
        </IonLabel>
      </IonItem>
      } )
      }
      
    </IonList>
  </>
}

export default HistorySection;

type TransactionHistoryModalState = {
  isOpen: "open" | "close"
  transaction?: Transaction
}

const TransactionHistoryModal = (props: {state: TransactionHistoryModalState, setState: React.Dispatch<React.SetStateAction<TransactionHistoryModalState>>, current_company? : Company}) => {
  const enterAnimation = (baseEl: HTMLElement) => {
    const root = baseEl.shadowRoot;

    const backdropAnimation = createAnimation()
      .addElement(root?.querySelector('ion-backdrop')!)
      .fromTo('opacity', '0.01', 'var(--backdrop-opacity)');

    const wrapperAnimation = createAnimation()
      .addElement(root?.querySelector('.modal-wrapper')!)
      .keyframes([
        { offset: 0, opacity: '0', transform: 'scale(0)' },
        { offset: 1, opacity: '0.99', transform: 'scale(1)' },
      ]);

    return createAnimation()
      .addElement(baseEl)
      .easing('ease-out')
      .duration(500)
      .addAnimation([backdropAnimation, wrapperAnimation]);
  };

  const leaveAnimation = (baseEl: HTMLElement) => {
    return enterAnimation(baseEl).direction('reverse');
  };

  return <IonModal 
    enterAnimation={enterAnimation}
    leaveAnimation={leaveAnimation}
    isOpen={props.state.isOpen === 'open'} 
    onDidDismiss={(e)=>{
      if(props.state.isOpen === 'open') {
        props.setState( { ...props.state, isOpen: "close" } )
      }
    }}
  >
  <IonHeader>
    <IonToolbar>
      <IonTitle>History Detail</IonTitle>
      <IonButtons slot="end">
        <IonButton className='text-danger' onClick={() => { props.setState({ ...props.state, isOpen: "close" }) }}>
          <IonIcon slot="icon-only" icon={closeOutline} />
        </IonButton>
      </IonButtons>
    </IonToolbar>
  </IonHeader>
  <IonContent className="ion-padding">
    <IonItemGroup>
      <IonItem lines='none'>
        <IonLabel className="font-bold">Request Amount</IonLabel>
        <IonLabel slot='end'>{ NewCurrencyCode(props.current_company?.countryCode) + ' ' + props.state.transaction?.amount.toLocaleString("en-SG", { maximumFractionDigits: 2 })}</IonLabel>
      </IonItem>
      <IonItem lines='none'>
        <IonLabel className="font-bold">Admin Fee</IonLabel>
        <IonLabel slot='end'>{ NewCurrencyCode(props.current_company?.countryCode) + ' ' + props.state.transaction?.fee.toLocaleString("en-SG", { maximumFractionDigits: 2 })}</IonLabel>
      </IonItem>
      <IonItem lines='none'>
        <IonLabel className="font-bold">Total You Get</IonLabel>
        <IonLabel slot='end'>{ NewCurrencyCode(props.current_company?.countryCode) + ' ' + props.state.transaction?.principal.toLocaleString("en-SG", { maximumFractionDigits: 2 })}</IonLabel>
      </IonItem>
      <hr className="border-gray border h-0 mb-2"/>
      <IonItem lines='none'>
        <IonLabel className="font-bold">Bank Name</IonLabel>
        <IonLabel slot='end'> { props.state.transaction?.bankCode } </IonLabel>
      </IonItem>
      <IonItem lines='none'>
        <IonLabel className="font-bold">Bank Account</IonLabel>
        <IonLabel slot='end'>{ props.state.transaction?.accountNumber }</IonLabel>
      </IonItem>
      <IonItem lines='none'>
        <IonLabel>
          <div className="font-bold">Withdraw Reason</div>
          <div>{ props.state.transaction?.reason }</div>
        </IonLabel>
      </IonItem>

      <IonItem lines='none'>
        <IonLabel>
          <div className="font-bold">Status</div>
          <div>
          {
            props.state.transaction?.status === 'SUCCESS' ?
            <IonBadge color={"success"}>Success</IonBadge> :
            props.state.transaction?.status === 'IN_PROCESS' ?
            <IonBadge color={"warning"}>In Process</IonBadge> :
            props.state.transaction?.status === 'FAILED' ?
            <IonBadge color={"danger"}>Failed</IonBadge>:
            <IonBadge color={""}>Unknown</IonBadge>
          }{
            props.state.transaction?.status === 'FAILED' &&
            <span className="align-text-bottom ml-1">{props.state.transaction?.transactionMessage}</span>
          }
          </div>
        </IonLabel>
      </IonItem>
    </IonItemGroup>
    <IonRow>
      <IonCol className="text-center">
        <IonButton className="mr-5" type="button" size="default" color={"danger"} onClick={() => props.setState({ ...props.state, isOpen: "close" })} >
          Close
        </IonButton>
      </IonCol>
    </IonRow>
  </IonContent>
</IonModal>
}