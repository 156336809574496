import { IonButton, IonButtons,  IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonModal, IonTitle, IonToolbar } from "@ionic/react";
import { useEffect,  useState } from "react";
import { closeOutline } from "ionicons/icons";
import { useAppDispatch } from "../../redux/hook";
import { loginByAuthTokenRedux } from "../../redux/auth/auth.action";
import { LoginToken } from "../../_services/auth-api/params";

export type MultiLoginModalState = {
  isOpen: "open" | "close"
  initialData: LoginToken[]
}

export type MultiLoginModalProps = {
  state: MultiLoginModalState
  setState: React.Dispatch<React.SetStateAction<MultiLoginModalState>>
}

const MultiLoginModal = (props: MultiLoginModalProps) => {
  const [globalLoginToken, setGlobalLoginToken] = useState<LoginToken[]>(props.state.initialData)
  useEffect(() => {
    if (props.state.isOpen === "open") {
      setGlobalLoginToken(props.state.initialData)
    } else if (props.state.isOpen === "close") {

    }
  }, [props.state])
  const dispatch = useAppDispatch();

  return <>
    <IonModal isOpen={props.state.isOpen === 'open'} >
      <IonHeader>
        <IonToolbar>

          <IonTitle>Choose Account</IonTitle>
          <IonButtons slot="end">
            <IonButton className='text-danger' onClick={() => { props.setState({ ...props.state, isOpen: "close" }) }}>
              <IonIcon slot="icon-only" icon={closeOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        {
          globalLoginToken.map((loginToken, i) => {
            return <IonItem key={"login-pick-" + loginToken.companyId} button type='button' onClick={async () => {
              await props.setState(
                { ...props.state, isOpen: "close" },
              )
              dispatch(loginByAuthTokenRedux(loginToken.authToken))
            }}>
              <IonLabel position="stacked">Login To:</IonLabel>
              <label className='pb-2 pt-1'>{loginToken.companyName}</label>
            </IonItem>
          })
        }
        <IonButton className='mt-4' type="button" expand="block" size="default" color={"danger"} onClick={() => props.setState({ ...props.state, isOpen: "close" })} >
          Cancel
        </IonButton>
      </IonContent>
    </IonModal>
  </>
}

export default MultiLoginModal;