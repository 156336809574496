import { createAnimation, IonButton, IonButtons, IonCol, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonItemGroup, IonLabel, IonModal, IonRow, IonSelect, IonSelectOption, IonSpinner, IonTitle, IonToolbar, useIonToast } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useEffect, useState } from "react";
import { TransactionApi } from "../../_services/transaction-api";
import { ApiErrorResponse } from "../../_network/response";
import { TransactionDraft } from "../../_services/transaction-api/params";
import { NewCurrencyCode } from "../../_helper/currencyCode";
import { EmployeeProfile } from "../../_services/employee-api/params";
import { Company } from "../../_services/company-api/params";

export type WithdrawModalState = {
  isOpen: "open" | "close"
  amount: number
}

const WithdrawModal = (props: {state: WithdrawModalState, setState: React.Dispatch<React.SetStateAction<WithdrawModalState>>, current_employee?: EmployeeProfile , current_company?: Company, onSubmitDone: (amount: number) => void}) => {
  interface form_param {
    reasonCategory: string;
    reason: string;
  }
  const [updating, setUpdating] = useState(false)
  const [reasonCategory, setReasonCategory] = useState("Daily Needs")
  const [transactionDraft, setTransactionDraft] = useState<TransactionDraft>();
  const [toast] = useIonToast();

  async function fetch(_amount: number) {
    try {
      var res = await TransactionApi.CheckTransactionRequest(_amount);
      setTransactionDraft(res.data);
    } catch (error) {
      if(error as ApiErrorResponse){
          toast({ message: (error as ApiErrorResponse).message, duration: 1500, position: "top", color: "danger", cssClass: "max-w-[480px] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" })
      } else {
          console.log("Unknown error:", error);
          toast({ message: "Internal Error", duration: 1500, position: "top", color: "danger" })
      }
      props.setState({ ...props.state, isOpen: "close"})
    }
  }

  useEffect(() => {
    if(props.state.isOpen === "open"){
      fetch(props.state.amount)
    } else {
      setTransactionDraft(undefined)
    }
  }, [props.state.isOpen])

  var categories = [
    "Daily Needs",
      "Bills",
      "Groceries",
      "Transport",
      "Education",
      "Health",
      "Insurance",
      "Donation",
      "Entertainment",
      "Investment",
      "Vacation",
      "Sports",
      "Tech/Gadget/Electronic",
      "Pet",
      "Home & Renovation",
      "Vehicle",
      "Payroll/Salary",
      "Other"   
  ]
  categories = categories.sort( (a, b) => {
    if( a === "Other"){ return 999; }
    if (a > b) { return 1; }
    if (a < b) { return -1; }
    return 0;
  })

  const ValidationSchema = yup.object().shape({
    reasonCategory: yup.string().required().oneOf(categories),
    reason:  yup.string().when("reasonCategory", {is: "Other", then: yup.string().required()}),
  })
  const { register, handleSubmit, setError, formState: { errors }, reset } = useForm<form_param>({ mode: 'onChange', resolver: yupResolver(ValidationSchema) });

  const onSubmitHandler = async (data: form_param) => {
    try {
      setUpdating(true)
      var desc = data.reasonCategory
      if(data.reason !== ""){
        desc += ": " + data.reason
      }
      await TransactionApi.CreateTransactionRequest(props.state.amount, desc)
      toast({ message: "Transaction Success", duration: 1500, position: "top", color: "success" })
      props.onSubmitDone(props.state.amount)
      props.setState({ ...props.state, isOpen: "close" })
      reset()
    } catch (error) {
      if (error as ApiErrorResponse) {
        (error as ApiErrorResponse).other_errors.forEach((e) => {
          switch (e.field) {
            case "description":
              setError("reasonCategory", { type: "focus", message: e.message }, { shouldFocus: true });
              break;
          }
        })
        toast({ message: (error as ApiErrorResponse).message, duration: 1500, position: "top", color: "danger", cssClass: "max-w-[480px] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" })
      } else {
        console.log("Unknown error:", error);
        toast({ message: "Internal Error", duration: 1500, position: "top", color: "danger" })
      }
    } finally {
      setUpdating(false)
    }
  };

  const enterAnimation = (baseEl: HTMLElement) => {
    const root = baseEl.shadowRoot;

    const backdropAnimation = createAnimation()
      .addElement(root?.querySelector('ion-backdrop')!)
      .fromTo('opacity', '0.01', 'var(--backdrop-opacity)');

    const wrapperAnimation = createAnimation()
      .addElement(root?.querySelector('.modal-wrapper')!)
      .keyframes([
        { offset: 0, opacity: '0', transform: 'scale(0)' },
        { offset: 1, opacity: '0.99', transform: 'scale(1)' },
      ]);

    return createAnimation()
      .addElement(baseEl)
      .easing('ease-out')
      .duration(250)
      .addAnimation([backdropAnimation, wrapperAnimation]);
  };

  const leaveAnimation = (baseEl: HTMLElement) => {
    return enterAnimation(baseEl).direction('reverse');
  };

  return <IonModal 
    enterAnimation={enterAnimation}
    leaveAnimation={leaveAnimation}
    isOpen={props.state.isOpen === 'open'} 
    onDidDismiss={(e)=>{
      if(props.state.isOpen === 'open') {
        props.setState( { ...props.state, amount: 0, isOpen: "close"} )
      }
    }}
  >
  <IonHeader>
    <IonToolbar>
      <IonTitle>Withdraw Request Detail</IonTitle>
      <IonButtons slot="end">
        <IonButton className='text-danger' onClick={() => { props.setState({ ...props.state, isOpen: "close" }) }}>
          <IonIcon slot="icon-only" icon={closeOutline} />
        </IonButton>
      </IonButtons>
    </IonToolbar>
  </IonHeader>
  <IonContent className="ion-padding">
    <IonItemGroup>
      <IonItem lines='none'>
        <IonLabel>Request Amount</IonLabel>
        <IonLabel slot='end'>{ NewCurrencyCode(props.current_company?.countryCode) + ' ' + transactionDraft?.amount?.toLocaleString("en-SG", { maximumFractionDigits: 2 })}</IonLabel>
      </IonItem>
      <IonItem lines='none'>
        <IonLabel>Admin Fee</IonLabel>
        <IonLabel slot='end'>{ NewCurrencyCode(props.current_company?.countryCode) + ' ' + transactionDraft?.fee?.toLocaleString("en-SG", { maximumFractionDigits: 2 })}</IonLabel>
      </IonItem>
      <IonItem lines='none'>
        <IonLabel>Total You Get</IonLabel>
        <IonLabel slot='end'>{ NewCurrencyCode(props.current_company?.countryCode) + ' ' + transactionDraft?.principal?.toLocaleString("en-SG", { maximumFractionDigits: 2 })}</IonLabel>
      </IonItem>
      <hr className="border-gray border h-0 mb-2"/>
      <IonItem lines='none'>
        <IonLabel>Bank Name</IonLabel>
        <IonLabel slot='end'> { props.current_employee?.bankName } </IonLabel>
      </IonItem>
      <IonItem lines='none'>
        <IonLabel>Account No</IonLabel>
        <IonLabel slot='end'>{ props.current_employee?.bankAccountNumber }</IonLabel>
      </IonItem>
      <IonItem lines='none'>
        <IonLabel>Account Name</IonLabel>
        <IonLabel slot='end'>{ props.current_employee?.bankAccountName }</IonLabel>
      </IonItem>
    </IonItemGroup>
    <form key="transaction-request-form" onSubmit={handleSubmit(onSubmitHandler)}>
      <IonRow>
        <IonCol>
          <IonItem lines="full" className="border border-b-0 rounded ">
            <IonLabel>Withdraw Reason</IonLabel>
            <IonSelect {...register("reasonCategory")} className='text-gray-400' 
              defaultValue={reasonCategory} value={reasonCategory}
              onIonChange={(e) => setReasonCategory(e.target.value)}
            >
              {
                categories.map((e, i)=>{
                  return <IonSelectOption key={"reason-category-"+i.toString()} value={e}>{e}</IonSelectOption>
                })
              }
              </IonSelect>
          </IonItem>
        </IonCol>
      </IonRow>
      <IonRow className={reasonCategory === "Other" ? "" : "hidden"}>
        <IonCol>
          <IonItem lines="full" className="border border-b-0 rounded mt-2">
            <IonInput {...register("reason")} name="reason" placeholder="Other Withdraw Reason" ></IonInput>
          </IonItem>
          <p className="text-left mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.reason && <>{errors.reason.message}</>)}</p>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol className="text-center">
          <IonButton className="mr-5" type="button" size="default" color={"danger"} onClick={() => props.setState({ ...props.state, isOpen: "close" })} >
            Cancel
          </IonButton>
          <IonButton className="ml-5" type="submit" size="default" disabled={updating} >
            Confirm
            {updating && <IonSpinner name="crescent" className="ml-2" />}
          </IonButton>
        </IonCol>
      </IonRow>
    </form>
  </IonContent>
</IonModal>
}

export default WithdrawModal;

