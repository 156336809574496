import { useAppSelector } from "../redux/hook";
import { IonReactRouter } from "@ionic/react-router";
import { IonLoading } from "@ionic/react";
import useLoginRoute from "./routes_login";
import useNonLoginRoute from "./routes_nonlogin";

const MainRoutes = () => {
  const { loading } = useAppSelector((state) => state.globalState);
  const { token } = useAppSelector((state) => state.auth);
  
  const login_route =  useLoginRoute()
  const nonlogin_route =  useNonLoginRoute()

  return <>
    <IonLoading
      cssClass={""}
      isOpen={loading}
      message={'Sedang memuat...'}
      showBackdrop={true}
      spinner={'crescent'}
      translucent={true}        
    />
    <IonReactRouter>
    { token ? 
    login_route : 
    nonlogin_route
    }
    </IonReactRouter>
  </>
  
};

export default MainRoutes;
