import { IonAvatar, IonBadge, IonContent, IonHeader, IonIcon, IonItem, IonItemDivider, IonItemGroup, IonLabel, IonList, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { callOutline, lockOpenOutline, logOutOutline } from 'ionicons/icons';
import useCurrentCompany from '../../hook/current_company';
import useCurrentEmployee from '../../hook/current_employee';
import { logoutRedux } from '../../redux/auth/auth.action';
import { useAppDispatch } from '../../redux/hook';

const AccountPage: React.FC = () => {
  const dispatch = useAppDispatch();

  const [current_employee] = useCurrentEmployee()
  const [current_company] = useCurrentCompany()
  
  const logout = (e: any) => {
    e.preventDefault();
    dispatch(logoutRedux());
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Account & Profil</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonList>
          <div className='p-4'>
            <div className="flex bg-white rounded-lg p-1.5">
              <IonAvatar >
                <img alt={current_employee?.name} src="https://ionicframework.com/docs/demos/api/avatar/avatar.svg" />
              </IonAvatar>
              <div className="flex flex-col px-5">
                <h4 className="font-bold text-lg m-0">{current_employee?.name}</h4>
                <p className="flex-1 text-sm text-slate-500">{current_company?.formalName}</p>
              </div>
            </div>
          </div>
          <hr className="border-gray border h-0 mb-2"/>
          <IonItemGroup>
            <IonItem lines='full'>
              <IonLabel>Phone Number</IonLabel>
              <IonLabel slot='end'>{current_employee?.phoneNumber}</IonLabel>
            </IonItem>
            <IonItem lines='full'>
              <IonLabel>Bank Name</IonLabel>
              <IonLabel slot='end'>{ current_employee?.bankCode }</IonLabel>
            </IonItem>
            <IonItem lines='full'>
              <IonLabel>Account No</IonLabel>
              <IonLabel slot='end'>{ current_employee?.bankAccountNumber }</IonLabel>
            </IonItem>
            <IonItem lines='full'>
              <IonLabel>Account Name</IonLabel>
              <IonLabel slot='end'>{ current_employee?.bankAccountName }</IonLabel>
            </IonItem>
            <IonItem lines='full'>
              <IonLabel>Bank Validation</IonLabel>
              {
              current_employee?.bankAccountStatus === "VALID" ?
              <IonBadge slot='end' color="success">{ current_employee?.bankAccountStatus }</IonBadge>:
              current_employee?.bankAccountStatus === "PENDING" ?
              <IonBadge slot='end' color="warning" className='text-white'>{ current_employee?.bankAccountStatus }</IonBadge>:
              <IonBadge slot='end' color="danger">{ current_employee?.bankAccountStatus }</IonBadge>
              }
            </IonItem>
          </IonItemGroup>

          <IonItemGroup>
            <IonItemDivider>
              <IonLabel>Account</IonLabel>
            </IonItemDivider>
            <IonItem lines='full' button href='/account/change-password'>
              <IonIcon className='mr-2' icon={lockOpenOutline}></IonIcon>
              <IonLabel>Change Password</IonLabel>
            </IonItem>
            <IonItem lines='full' button href={"https://api.whatsapp.com/send?phone=6281231747236&text=Hi+Gajiku"} target="_blank" rel="noreferrer">
              <IonIcon className='mr-2' icon={callOutline}></IonIcon>
              <IonLabel>Need Help? Chat us</IonLabel>
            </IonItem>
            <IonItem color={"danger"} lines='full' button onClick={ logout }>
              <IonIcon className='mr-2' icon={logOutOutline}></IonIcon>
              <IonLabel>Logout</IonLabel>
            </IonItem>
            
          </IonItemGroup>
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default AccountPage;
